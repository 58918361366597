<template>
  <CRow class="justify-content-center">
    <CCol class="c-col" col="11" md="12" lg="11">
      <CCard class="ccard shadow-none" color="second-card-bg">
        <CCardBody class="ccard-body">
          <div class="cheader">
            <a :href="homepage_url">
              <logo-wrap :auth="true" />
            </a>
            <div class="d-flex align-items-center">
              <div class="mr-3 cursor-pointer" @click="darkMode = !darkMode">
                <CIcon v-if="darkMode" name="cil-sun"/>
                <CIcon v-else name="cil-moon"/>
              </div>
              <CSelect
                :value.sync="locale"
                :options="$store.state.localeOptions"
                class="mb-0"
              />
            </div>
          </div>

          <h1 class="title text-white">{{$t('formRecovery.title')}}</h1>
          <p class="subtitle text-muted">{{$t('formRecovery.subtitle')}}</p>

          <div v-if="statusSuccess">
            <h5>{{textSuccess}}</h5>
          </div>

          <CForm
            v-else
            class="cform"
            @submit.prevent="submit"
          >
            <CInput
              v-model="email"
              placeholder="Введите E-mail"
              autocomplete="email"
              class="cinput"
              :is-valid="detectErrorField(errors.email)"
              :invalid-feedback="errors.email"
            >
              <template #prepend-content><CIcon name="mail"/></template>
            </CInput>

            <CRow class="subactions">
              <CCol col="12" xl="6">
                <CButton block color="dark" class="cbtn shadow-none" @click="$router.go(-1)">Назад</CButton>
              </CCol>
              <CCol col="12" xl="6">
                <CLoadingButtonCustom
                  block
                  color="primary"
                  class="cbtn shadow-none"
                  type="submit"
                  :loading="loadingRequest"
                  :disabled="loadingRequest"
                >
                  Восстановить
                </CLoadingButtonCustom>
              </CCol>
            </CRow>
          </CForm>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import LogoWrap from "@/components/LogoWrap";
import axios from "axios";
import { formatErrorObject, detectErrorField } from "@/assets/js/axios/responseErrorHandler";

export default {
  name: "Recovery",
  components: {
    LogoWrap
  },
  data () {
    return {
      detectErrorField,
      loadingRequest: false,
      email: '',
      errors: {},
      statusSuccess: false,
      textSuccess: this.$t('lk.account.resetLinkOnEmail')
    }
  },
  computed: {
    locale: {
      get () {
        return this.$root.$i18n.locale
      },
      set (lang) {
        this.$root.$i18n.locale = lang
        this.$store.commit("SET_KEY", {
          namespace: 'global',
          key: 'locale',
          value: lang
        })
      }
    },
    darkMode: {
      get () {
        return this.$store.getters.darkMode
      },
      set (val) {
        this.$store.commit("SET_KEY", {
          namespace: 'global',
          key: 'darkMode',
          value: val
        })
      }
    },
    homepage_url() {
      let hp = process.env.VUE_APP_HOMEPAGE_URL
      if(hp && hp.slice(-1) !== '/'){
        hp += '/'
      }
      if(this.locale === 'ru'){
        hp += 'ru/';
      }
      return hp;
    },
  },
  created () {
    this.setInitParam()
  },
  methods: {
    setInitParam () {
      const email = this.$route.params?.email
      if (email) {
        this.email = email
      }
    },
    submit() {
      this.loadingRequest = true
      axios.post('/api/v1/password-recovery/send-reset-link-email', {
        locale: this.$root.$i18n.locale,
        email: this.email
      }, {
        headers: {
          'skipAuthToken': true
        }
      })
        .then(({status}) => {
          if (status < 300) {
            this.errors = {}
            this.statusSuccess = true
            this.$toastr.success(this.textSuccess)
          }
        })
        .catch(error => {
          if (error?.response?.status === 422) {
            const errorObj = error?.response?.data?.errors
            this.errors = formatErrorObject(errorObj)
          }
        })
        .finally(() => {
          this.loadingRequest = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
//@import "@/assets/scss/style";

.col-xs {
  @include media-breakpoint-down(xs) {
    flex: 0 0 16.875rem;
    max-width: 16.875rem;
  }
}
.cheader {
  display: flex;
  justify-content: space-between;
}
.ccard {
  padding: 0;
  margin: 0;

  .title {
    text-align: center;
  }
  .subtitle {
    text-align: center;
  }
  .cinput {
    margin-bottom: 0.625rem;

    /deep/ .form-control {
      height: 2.75rem;

      @include media-breakpoint-down(lg) {
        font-size: 1rem !important;
      }
    }
  }
  .subactions {
    flex-direction: column-reverse;

    & > :last-child {
      margin-bottom: 0.625rem;
    }
  }
  .cbtn {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    height: 2.75rem;
  }

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1.875rem 0.9375rem 3.75rem;
    }
    .cheader {
      margin-bottom: 0.375rem;
    }
    .title {
      font-size: 1.5rem;
    }
    .subtitle {
      margin-bottom: 1.875rem;
    }
  }
  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 3rem;
    }
    .cheader {
      margin-bottom: 1.5rem;
    }
    .subtitle {
      margin-bottom: 2.8125rem;
    }
    .cform {
      padding: 0 1.5rem;
    }
  }

  @include media-breakpoint-up(md) {
    .ccard-body {
      padding: 2.875rem 3.4375rem 5rem;
    }
  }
  @include media-breakpoint-up(xl) {
    .ccard-body {
      padding: 4rem 8.625rem 6.25rem;
    }
    .title {
      text-align: start;
    }
    .subtitle {
      text-align: start;
      margin-bottom: 1.875rem;
    }
    .cform {
      padding: 0;
    }
    .cinput {
      margin-bottom: 1.5625rem;
    }
    .subactions {
      flex-direction: row;

      & > :last-child {
        margin-bottom: 0;
      }
    }
  }
}

.c-col {
  padding: 1rem 0;

  @include media-breakpoint-up(md) {
    padding: 1.5rem 0;
  }
}
</style>
